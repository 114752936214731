import React from 'react'

const Editing = () => {
  return (
    <div className="skills_content">
        <h3 className="skills_title">Tools and Technologies</h3>

        <div className="skills_box">
            <div className="skills_group">
                <div className="skills_data">
                    <i class='bx bx-badge-check'></i>

                    <div>
                        <h3 className="skills_name">Docker</h3>
                        <span className="skills_level"></span>
                    </div>
                </div>

                <div className="skills_data">
                    <i class='bx bx-badge-check'></i>

                    <div>
                        <h3 className="skills_name">AWS Services</h3>
                        <span className="skills_level"></span>
                    </div>
                </div>

                <div className="skills_data">
                    <i class='bx bx-badge-check'></i>

                    <div>
                        <h3 className="skills_name">MySQL</h3>
                        <span className="skills_level"></span>
                    </div>
                </div>
            </div>

            <div className="skills_group">
                <div className="skills_data">
                    <i class='bx bx-badge-check'></i>

                    <div>
                        <h3 className="skills_name">Git</h3>
                        <span className="skills_level"></span>
                    </div>
                </div>

                <div className="skills_data">
                    <i class='bx bx-badge-check'></i>

                    <div>
                        <h3 className="skills_name">Firebase</h3>
                        <span className="skills_level"></span>
                    </div>
                </div>

                <div className="skills_data">
                    <i class='bx bx-badge-check'></i>

                    <div>
                        <h3 className="skills_name">MongoDB</h3>
                        <span className="skills_level"></span>
                    </div>
                </div>
            </div>
        </div>
    </div>
  );
};

export default Editing;