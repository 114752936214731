import React from 'react'

const Frontend = () => {
  return (
    <div className="skills_content">
        <h3 className="skills_title">Frontend Developer</h3>

        <div className="skills_box">
            <div className="skills_group">
                <div className="skills_data">
                    <i class='bx bx-badge-check'></i>

                    <div>
                        <h3 className="skills_name">HTML 5</h3>
                        <span className="skills_level"></span>
                    </div>
                </div>

                <div className="skills_data">
                    <i class='bx bx-badge-check'></i>

                    <div>
                        <h3 className="skills_name">CSS 3</h3>
                        <span className="skills_level"></span>
                    </div>
                </div>

                <div className="skills_data">
                    <i class='bx bx-badge-check'></i>

                    <div>
                        <h3 className="skills_name">JavaScript</h3>
                        <span className="skills_level"></span>
                    </div>
                </div>
            </div>

            <div className="skills_group">
                <div className="skills_data">
                    <i class='bx bx-badge-check'></i>

                    <div>
                        <h3 className="skills_name">ReactJS</h3>
                        <span className="skills_level"></span>
                    </div>
                </div>

                <div className="skills_data">
                    <i class='bx bx-badge-check'></i>

                    <div>
                        <h3 className="skills_name">React Native</h3>
                        <span className="skills_level"></span>
                    </div>
                </div>

                <div className="skills_data">
                    <i class='bx bx-badge-check'></i>

                    <div>
                        <h3 className="skills_name">TailwindCSS</h3>
                        <span className="skills_level"></span>
                    </div>
                </div>
            </div>
        </div>
    </div>
  );
};

export default Frontend;